import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'
import { PostHeader } from '../components/postHeader'

import event_manager from '../images/event_manager.jpg'
import event_manager_small from '../images/event_manager-216x300.jpg'
import event_rat1 from '../images/event_rat1.jpg'
import event_rat1_small from '../images/event_rat1-281x300.jpg'
import event_rat3 from '../images/event_rat3.jpg'
import event_rat3_small from '../images/event_rat3-300x205.jpg'
import event_rat4 from '../images/event_rat4.jpg'
import event_rat4_small from '../images/event_rat4-222x300.jpg'

// http://www.dktech.cz/?lang=cz&paged=17
// http://www.dktech.cz/?p=84#more-84

const PostPodzimniLovMysi: FC = () => {
  return (
    <PageTemplate
      prevLink="/post_stehovani_odstavka"
      nextLink="/post_priprava"
    >
      <PostHeader
        title="Podzimní lov myší"
        link="/post_podzimni_lov_mysi"
        author="Algy"
        date="28.11.2013"
        time="10:05"
        category="Eventy"
      />
      <h2><strong><span style={{ textDecoration: 'underline' }}>27. 11. 2013 – 8. 12. 2013</span></strong></h2>
      <p style={{ textAlign: 'justify' }}>&nbsp; &nbsp; Obávaný pirát Zaken proklel svou zrádnou posádku, která se mu pokusila ukrást poklad přímo pod nosem. Zakenova vnučka Bonny se snaží všechno napravit. Potřebuje Vaši pomoc k osvobození posádky z prokletí, které je všechny změnilo ve velké myši!</p>
      <p style={{ textAlign: 'justify' }}><span id="more-84"></span></p>
      <p style={{ textAlign: 'center' }}>&nbsp;<a href={event_manager}><img className="size-medium wp-image-85 aligncenter" alt="event_manager" src={event_manager_small} width="216" height="300" /></a></p>
      <p style={{ textAlign: 'center' }}><em>Zakenova vnučka Bonny</em></p>
      <p style={{ textAlign: 'justify' }}><i>&nbsp; &nbsp; &nbsp;„Blázni! Věřili byste tomu? Jeho vlastní posádka ho okrade. Člověk by si myslel, že po fiasku s prasaty by se měli třást strachem při pouhém pomyšlení na okradení svého všemocného nemrtvého kapitána. Ale oni jsou nepoučitelní. A teď se na ně podívejte! Za trest byli proměněni v myši. Jíst sýr po celý den se možná nezdá být špatným osudem, ale… I tak je mi jich trochu líto.</i></p>
      <p style={{ textAlign: 'justify' }}><i>&nbsp; &nbsp; &nbsp;Nemám příliš soucitu s prokletou posádkou. Jsou to vyvrhelové a lumpové hrubého zrna. Mám ale jeden problém. Kdo bude teď obsluhovat jeho loď? Já ne, to je jisté! Myslím, že posádka již dostala svoji lekci. Když mi pomůžete tento zmatek napravit a posádku ze zakletí osvobodit, dobře se vám odměním. V dobrém o Vás promluvím i u dědečka Zakena. Co na to říkáte?“</i></p>
      <p><strong><span style={{ textDecoration: 'underline' }}>K pravidlům eventu</span></strong></p>
      <p style={{ textAlign: 'justify' }}>Ve hře nyní najdete čtyři různé typy prokleté posádky:</p>
      <p style={{ textAlign: 'justify' }}><strong>Cursed Raiders</strong>:&nbsp;Obyčejní piráti proměnění v myši, kteří se pohybují osamoceně, ale mohou vás omráčit, pokud na ně zaútočíte. Vypalte na ně Pardon shot pro nejúčinnější výsledek.</p>
      <p style={{ textAlign: 'center' }}><a href={event_rat1}><img className="size-medium wp-image-86 aligncenter" alt="event_rat1" src={event_rat1_small} width="281" height="300" /></a></p>
      <p><b>Cursed Deckhands</b>:&nbsp;Tito zabělí piráti se pohybují ve skupinách a v případě napadení přivolávají Cursed Buccaneer.</p>
      <p style={{ textAlign: 'center' }}><a href={event_rat3}><img className="size-medium wp-image-87 aligncenter" alt="event_rat3" src={event_rat3_small} width="300" height="205" /></a></p>
      <p><b>Cursed Buccaneers:</b>&nbsp;Největší a nejstatnější ze všech pirátů, Cursed Buccaneer se nejlépe loví v&nbsp;partě.</p>
      <p style={{ textAlign: 'center' }}><a href={event_rat4}><img className="size-medium wp-image-88 aligncenter" alt="event_rat4" src={event_rat4_small} width="222" height="300" /></a></p>
      <p><strong>Cursed Lookouts</strong>:&nbsp;Samotářští piráti, kteří stáli za organizací samotné krádež, a proto mají u sebe nejvíce Mouse Coins.</p>
      <p style={{ textAlign: 'justify' }}><strong><span style={{ textDecoration: 'underline' }}>Jak na to?</span></strong></p>
      <p style={{ textAlign: 'justify' }}>&nbsp; &nbsp; &nbsp;Kupte si Redemption Bow od Bonny a používejte skilly “Forgiveness” a “Pardon” na prokletou posádku, abyste ji zbavili Zakenova prokletí. Redemption Bow sám je nabit magickou energií, která ruší prokletí. S&nbsp;každým výstřelem a s&nbsp;přibývajícím časem, kdy je luk ve vašich rukou, magická energie mizí až nakonec stráví celý luk. Každých 24 hodin můžete mladou Bonny požádat až o tři luky. Ve chvíli, kdy vyléčíte člena prokleté posádky, získáte Mouse Coins. Bonny Vás následně odmění kdykoliv jí odevzdáte 20 Mouse Coins. Sesbírejte tolik Mouse Coins kolik jen můžete!</p>
      <p style={{ textAlign: 'justify' }}><strong><span style={{ textDecoration: 'underline' }}>Odměny</span></strong></p>
      <p style={{ textAlign: 'justify' }}>&nbsp; &nbsp; &nbsp;Vždy, když Bonny odevzdáte 20 Mouse Coins, dostanete od ní buffy odpovídající Vašemu povolání a náhodnou odměnu (např. bufovací svitky, BSOE, BRESS, C, B, A a S grade enchanty na armory i zbraně; dále je možné získat high a top grade life stone lvl 70 a 76 anebo náhodný elemental stone)!<br />
        Pokud budete mít štěstí, můžete získat i:&nbsp;Golden Circlet of Redemption,&nbsp;Hunting Cap,&nbsp;Silver Circlet of Salvation nebo&nbsp;White Cupid Agathion.</p>
      <p style={{ textAlign: 'justify' }}>&nbsp; &nbsp; &nbsp;Během eventu můžete také najít artefakt známý jako Salvation Bow. Tento luk vypadá stejně jako Redemption Bow, avšak pradávní stvořitelé jej určili k&nbsp;rozdávání štěstí. Není v&nbsp;něm ukrytá žádná magická síla, ale můžete ho použít na své přátele dle libosti. Upozorňujeme, že žádný ze Salvations Bow není určen pro boj nebo léčení prokletí.</p>
      <p style={{ textAlign: 'justify' }}>Za DKTech tým vám přeji příjemnou zábavu.</p>
      <p style={{ textAlign: 'justify' }}>S&nbsp;pozdravem Algy.</p>
      <div className="cleared"></div>
      <span className="linkpages"></span><div className="cleared"></div>
    </PageTemplate>
  )
}

export default PostPodzimniLovMysi

export { Head } from '../components/defaultHtmlHead'